<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainForm from "@/components/MainForm.vue";
import FormTitle from "@/components/FormTitle.vue";
export default {
	components: {
		FormTitle,
		SecondaryButton,
		MainForm,
	},
	data() {
		return {
			showForm: false,
			listed: false,
			action: "Agregar",
			agencies: [],
			agency: {
				id: { name: "Id", value: null },
				name: { name: "Nombre", value: null },
				ceco: { name: "Ceco", value: null },
				territory_id: { name: "Territorio", value: null, options: {} },
				region_id: { name: "Región", value: null, options: {} },
			},
		};
	},
	created() {
		this.loadFilters(true);
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			readProfile: "auth/readUser",
			//READ_FILTERS
			readRegions: "regionsStore/readRegions",
			readTerritories: "getTerritories/readTerritories",
			//READ_AGENCIES
			readAgencies: "agenciesStore/readAgencies",
			//READ_ERRORS
			agencyActionsError: "agenciesStore/readError",
			createError: "agenciesStore/readError",
			updateError: "agenciesStore/readError",
			deleteError: "agenciesStore/readError",
			regionsError: "regionsStore/readError",
			territoriesError: "getTerritories/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_FILTERS_ID
			setRegionsFilter: "regionsStore/SET_FILTER",
			setRegionId: "regionsStore/SET_ID",
			setAgenciesFilter: "agenciesStore/SET_FILTER",
			setAgencyId: "agenciesStore/SET_ID",

			//SET_AGENCIES
			setCreateAgency: "agenciesStore/SET_AGENCY",
			setUpdateAgency: "agenciesStore/SET_AGENCY",
			setUpdateId: "agenciesStore/SET_ID",
			setDeleteId: "agenciesStore/SET_ID",

			//SET_ERRORS
			setGetError: "agenciesStore/SET_ERROR",
			setCreateError: "agenciesStore/SET_ERROR",
			setUpdateError: "agenciesStore/SET_ERROR",
			setDeleteError: "agenciesStore/SET_ERROR",
			setRegionsError: "regionsStore/SET_ERROR",
			setTerritoriesError: "getTerritories/SET_ERROR",
		}),
		...mapActions({
			//GET_FILTERS
			getTerritories: "getTerritories/getTerritories",
			regionActions: "regionsStore/regionActions",
			//AGENCIES
			agencyActions: "agenciesStore/agencyActions",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadFilters(start = false) {
			this.clearErrors();
			this.setLoading(true);
			this.listed = false;
			this.agency.territory_id.options = {};
			this.agency.territory_id.value = null;
			this.agency.region_id.options = {};
			this.agency.region_id.value = null;

			//TERRITORIES
			this.getTerritories()
				.then((response) => {
					if (response) {
						if (this.readTerritories.length > 0) {
							for (let t in this.readTerritories) {
								this.agency.territory_id.options[
									this.readTerritories[t].id
								] = this.readTerritories[t].name;
							}
							if (start) {
								this.agency.territory_id.value =
									this.readProfile.territory_id;
								this.refreshFilters("territory_id", true);
							} else {
								this.refreshFilters("territory_id");
							}
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		refreshFilters(selected, start = false) {
			this.clearErrors();
			this.setLoading(true);
			this.listed = false;

			let filter = this.agency[selected].value;

			if (selected == "territory_id") {
				this.agency.region_id.options = {};
				this.agency.region_id.value = null;
				//REGIONS
				this.setRegionId(null);
				this.setRegionsFilter(filter);
				this.regionActions("getRegions")
					.then((response) => {
						if (response) {
							if (this.readRegions.length > 0) {
								for (let r in this.readRegions) {
									this.agency.region_id.options[
										this.readRegions[r].id
									] = this.readRegions[r].name;
								}
								if (start) {
									this.agency.region_id.value =
										this.readProfile.region_id;
								}
							} else if (start) {
								this.loadFilters();
							}
						}
					})
					.finally(() => {
						this.loadAgencies();
						this.setLoading(false);
					});
			} else {
				this.loadAgencies();
			}
		},
		loadAgencies() {
			this.clearErrors();
			this.setLoading(true);
			this.listed = false;
			let region = this.agency.region_id.value;

			this.agencies = [];

			//AGENCIES
			this.setAgencyId(null);
			this.setAgenciesFilter(region);
			this.agencyActions("getAgencies")
				.then((response) => {
					if (response) {
						if (this.readAgencies.length > 0) {
							this.agencies = this.readAgencies;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
					this.listed = true;
				});
		},
		toggleForm() {
			this.clearErrors();
			this.showForm = !this.showForm;
		},
		update(data) {
			this.clearErrors();
			this.action = "Actualizar";
			for (let a in this.agency) {
				this.agency[a]["value"] = data[a];
			}
			this.toggleForm();
		},
		remove(agency) {
			this.clearErrors();
			this.setLoading(true);
			this.$swal({
				title: "¿Desea eliminar esta Agencia?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(agency.id.value || agency.id);
					this.deleteAgency().then((result) => {
						if (result) {
							this.loadAgencies();
							this.$swal({
								icon: "success",
								title: `La Agencia ${
									agency.name.value || agency.name
								} ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});
			this.setLoading(false);
		},
		submit() {
			this.clearErrors();
			this.setLoading(true);
			let agency = this.agency;
			let output = {};

			for (let a in agency) {
				output[a] = agency[a].value;
			}

			if (this.action == "Agregar") {
				this.setCreateAgency(output);
				this.agencyActions("createAgency").then((result) => {
					if (result) {
						this.loadAgencies();
						this.toggleForm();
						this.clear();
						this.$swal({
							icon: "success",
							title: `La Agencia ${output.name} ha sido creada correctamente.`,
							showConfirmButton: false,
							timer: 3000,
						});
					}
				});
			} else {
				this.setUpdateId(output.id);
				this.setUpdateAgency(output);

				this.agencyActions("updateAgency").then((result) => {
					if (result) {
						this.loadAgencies();
						this.toggleForm();
						this.clear();
						this.$swal({
							icon: "success",
							title: `La Agencia ${output.name} se actualizó con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
					}
				});
			}
			this.setLoading(false);
		},
		clear() {
			for (let a in this.agency) {
				this.agency[a].value = null;
			}
			this.action = "Agregar";
		},
		clearErrors() {
			//AGENCIES
			this.setGetError(null);
			this.setCreateError(null);
			this.setDeleteError(null);
			this.setUpdateError(null);
			//FILTERS
			this.setTerritoriesError(null);
			this.setRegionsError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>
				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6 flex-none"
				/>
				<form-title
					:action="action"
					text="Punto de "
					textBold="Venta"
					:name="agency.name.value"
				></form-title>
				<!-- DISPLAY FORM -->
				<form class="grid grid-cols-2 gap-5 items-center">
					<label
						class="col-span-2 text-lg"
						:class="{
							'md:col-span-1 m-0':
								key == 'territory_id' || key == 'region_id',
						}"
						:for="key"
						v-for="(a, key, index) in agency"
						:key="key"
						v-show="key != 'id'"
						ref="labels"
					>
						<span class="block">{{ a.name }}</span>
						<textarea
							v-if="key != 'region_id' && key != 'territory_id'"
							:ref="index"
							:required="key != 'id'"
							class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
							:name="key"
							:placeholder="a.name"
							v-model="a.value"
							rows="2"
							v-capitalizeInput
						/>
						<select
							@change="refreshFilters(key)"
							class="w-full px-4 py-2 text-lg bg-gray-light rounded-md h-12"
							:name="a.name"
							:id="key"
							v-if="key == 'region_id' || key == 'territory_id'"
							v-model="a.value"
						>
							<option
								v-if="key == 'territory_id'"
								disabled
								value="null"
							>
								Seleccionar territorio
							</option>
							<option
								v-if="key == 'region_id'"
								disabled
								value="null"
							>
								Seleccionar región
							</option>
							<option
								v-for="(option, index) in a.options"
								:key="index"
								:value="index"
								v-capitalizeWords="option"
							></option>
						</select>
					</label>
					<span
						v-if="agencyActionsError"
						class="block mt-5 red text-center b col-span-2"
						>{{ agencyActionsError }}</span
					>
					<span
						v-if="deleteError"
						class="block mt-5 red text-center b col-span-2"
						>{{ deleteError }}</span
					>
					<span
						v-if="createError"
						class="block mt-5 red text-center b col-span-2"
						>{{ createError }}</span
					>
					<span
						v-if="updateError"
						class="block mt-5 red text-center b col-span-2"
						>{{ updateError }}</span
					>
					<span
						v-if="regionsError"
						class="block mt-5 red text-center b col-span-2"
						>{{ regionsError }}</span
					>
					<span
						v-if="territoriesError"
						class="block mt-5 red text-center b col-span-2"
						>{{ territoriesError }}</span
					>
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						<SecondaryButton
							v-if="action == 'Actualizar'"
							@click.native="remove(agency)"
							text="Eliminar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							@click.native="[toggleForm(), clear()]"
							v-else
							text="Cancelar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							:text="action"
							@click.native="submit"
							class="flex-none"
						/>
					</div>
				</form>
			</div>
		</div>
		<main-form
			v-on:toggleForm="toggleForm"
			:action="action"
			text="Puntos de"
			textBold="Venta"
		></main-form>
		<table
			v-if="!isLoading && agencies.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group bg-green-dark">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						v-for="(field, key) in agency"
						:key="key"
						v-show="key != 'id'"
					>
						{{ field.name }}
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						agencies.length > 1
							? 'w-full md:w-1/2 lg:w-full'
							: 'w-full'
					"
					v-for="(a, i) in agencies"
					:key="i"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						:class="{ 'green b': key == 'id' }"
						v-for="(field, key) in agency"
						:key="key"
						v-show="key != 'id'"
					>
						<span
							v-capitalizeWords="
								field.options
									? field.options[a[key]] || a[key]
									: a[key]
							"
						>
						</span>
					</td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
								class="title-hint"
								aria-label="Da clic aquí para editar esta Agencia"
							>
								<font-awesome-icon
									@click="update(a)"
									class="green hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-pen-to-square"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Da clic aquí para eliminar esta Agencia"
							>
								<font-awesome-icon
									@click="remove(a)"
									class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-trash-can"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron agencias</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "Nombre", 2 "Ceco", 3 "Territorio", 4 "Región";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i + 1}):before {
			content: $field;
		}
	}
}
</style>
